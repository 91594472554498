import React, { useRef, useState } from "react"
import emailjs from "@emailjs/browser"

const Partnership = () => {
  const form = useRef<HTMLFormElement>(null)

  const [result, showResult] = useState(false)

  const sendEmail = e => {
    e.preventDefault()

    if (!form.current) return

    emailjs
      .sendForm(
        "service_ow0yzdq",
        "template_kok9yri",
        form.current,
        "9sVOYX0vzUl0KnRKl"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
    showResult(true)
  }

  return (
    <div className="flex flex-row items-center lg:ml-32 justify-around w-[100%] lg:h-screen h-auto">
      <div className="flex flex-col lg:w-[30%] w-[90%] justify-start">
        <h2 className="text-white text-4xl lg:mt-0 mt-32 font-bold mb-10">
          Partner with Us
        </h2>
        <div className="flex flex-col w-[100%] mb-10 lg:hidden">
          <div className="w-[90%] aspect-[5/4] bg-blue-300 rounded-tr-[10%] rounded-bl-[10%]">
            eee
          </div>
        </div>
        <p className="text-slate-500 text-md">
          PAR 26: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
          diam nonummy nibh euismod. Lorem ipsum dolor sit amet, consectetuer
          adipiscing elit, sed diam
        </p>
        <form
          className="flex flex-col items-center"
          ref={form}
          onSubmit={sendEmail}
        >
          <input
            type="text"
            name="from_name"
            placeholder="Name"
            className="w-[100%] border-0 !ring-0 outline-none border-b-2 py-2 focus:border-white bg-transparent my-3 text-white"
          />
          <input
            type="text"
            name="Email"
            placeholder="E-mail"
            className="w-[100%] border-b-2 py-2 border-0 !ring-0 outline-none focus:border-white bg-transparent my-3 text-white"
          />
          <button
            type="submit"
            className="w-[100%] border-2 py-2 px-20 lg:mt-10 my-5 text-white"
          >
            Send Request
          </button>
        </form>
        <div>
          {result ? (
            <div className="text-slate-300 text-2xl bg-green-300 bg-opacity-50 p-2 px-4 border-2 mt-5 rounded text-center ">
              <p>E-mail sent</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="lg:flex lg:flex-col lg:w-[45%] hidden">
        <div className="w-[60%] aspect-[5/4] bg-blue-300 rounded-tr-[10%] rounded-bl-[10%]">
          eee
        </div>
      </div>
    </div>
  )
}

export default Partnership
