import React from "react"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Partnership from "../components/Partnership/Partnership"

const partnershipPage = () => {
  return (
    <div>
      <Navbar />
      <article className="w-full h-auto flex items-center justify-center">
        <Partnership />
      </article>
      <Footer />
    </div>
  )
}

export default partnershipPage
